import api from '../../api'

let state = {
	id: null,
	user: [],
	usersingle: {},
}

let getters = {
	userlist: (state) => state.user,
	usersingle: (state) => state.usersingle,
}

let actions = {
	createuseradmin({ commit }, user) {
		return new Promise((resolve, reject) => {
			api.post('/api/user/store', user)
				.then((res) => {
					const user = res
					commit('CREATE_USER', res.data, user)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	driverRegistrationUser({ commit }, data) {
		return new Promise((resolve, reject) => {
			api.post('/api/register-driver', data)
				.then((res) => {
					commit('CREATE_DRIVER_USER'), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	userdata({ commit }) {
		return new Promise((resolve, reject) => {
			api.get('/api/user/index')
				.then((res) => {
					if (!res.data.error) {
						const user = res.data
						commit('USER_DATA', res.data, user)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// userUpdate({ commit }, user) {
	// 	return new Promise((resolve, reject) => {
	// 		api.put(`api/user/update/${user.id}`, user)
	// 			.then((res) => {
	// 				if (!res.data.error) {
	// 					const userData = res.data
	// 					commit('USER_UPDATE', res.data, userData)
	// 				}
	// 				resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	userUpdatechange({ commit }, user) {
		return new Promise((resolve, reject) => {
			api.post(`api/user/password/change`, user)
				.then((res) => {
					const userData = res.data
					commit('USER_PASS_UPDATE', res.data, userData), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	userview({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.get(`api/user/show/${id}`)
				.then((res) => {
					const Usersingle = res.data
					commit('USER_VIEW', res.data, Usersingle), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// userdelete({ commit }, id) {
	// 	return new Promise((resolve, reject) => {
	// 		api.delete(`api/user/destroy/${id}`)
	// 			.then((res) => {
	// 				commit('USER_DELETE', id), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	userdelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.post(`api/user/destroy/${id}`, { _method: 'DELETE' })
				.then((res) => {
					commit('USER_DELETE', id), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

let mutations = {
	CREATE_USER(state, user) {
		// state.user.unshift(User)
	},
	CREATE_DRIVER_USER(state) {
		state.status = 'success'
	},
	USER_DATA(state, user) {
		state.status = 'success'
		state.user = user
	},
	USER_UPDATE(state, userData) {
		state.status = 'success'

		// const index = state.user.findIndex(user => user.id === userData.id);
		// if (index !== -1) {
		//     state.user.splice(index, 1, userData);
		// }
	},
	USER_VIEW(state, Usersingle) {
		state.status = 'success'
		state.Usersingle = Usersingle
	},

	USER_DELETE(state, id) {
		state.status = 'success'
		state.user = state.user.filter((user) => user.id !== id)
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
