import api from '../../api'
import { createGetters, createPaginaion } from 'vuelpers'

const state = () => ({
	supervisors: createPaginaion(),
})

const getters = {
	$supervisors: (state) => state.supervisors,
	$pagination: (state) => ({
		page: state.supervisors.current_page,
		per_page: state.supervisors.per_page,
	}),
}

const mutations = {
	CREATE_SUPERVISOR(state, payload) {
		state.supervisors.total += 1
		state.supervisors.data.unshift(payload)
	},
	SET_SUPERVISORS(state, payload) {
		state.supervisors = payload
	},
	UPDATE_SUPERVISOR(state, payload) {
		state.supervisors.data = state.supervisors.data.map((supervisor) =>
			supervisor.id === payload.id ? payload : supervisor
		)
	},
	DELETE_SUPERVISOR(state, payload) {
		state.supervisors.data = state.supervisors.data.filter(
			(supervisor) => supervisor.id !== payload.id
		)
	},
	setPerPage(state, payload) {
		state.supervisors.per_page = payload
	},
}

let actions = {
	getSupervisors({ commit }, payload) {
		return new Promise((resolve, reject) => {
			const { page = 1, per_page = 15 } = payload || {}
			api.get(`/api/supervisors?page=${page}&per_page=${per_page}`)
				.then((res) => {
					commit('SET_SUPERVISORS', res.data.supervisors)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	CreateSupervisor({ commit }, supervisor) {
		return new Promise((resolve, reject) => {
			api.post('/api/supervisors', supervisor)
				.then((res) => {
					if (!res.data.error) {
						const supervisor = res.data
						commit('CREATE_SUPERVISORS', supervisor)
					}
					resolve(res)
				})
				.catch((err) => reject(err))
		})
	},
	UpdateSupervisor({ commit }, supervisor) {
		return new Promise((resolve, reject) => {
			api.post('/api/supervisors/' + supervisor.id, supervisor)
				.then((res) => {
					commit('UPDATE_SUPERVISOR', res.data.supervisor)
					resolve(res)
				})
				.catch((err) => reject(err))
		})
	},
	// DeleteSupervisor({ commit }, supervisor) {
	// 	return new Promise((resolve, reject) => {
	// 		api.delete('/api/supervisors/' + supervisor)
	// 			.then((res) => {
	// 				commit('DELETE_SUPERVISOR', supervisor)
	// 				resolve(res)
	// 			})
	// 			.catch((err) => reject(err))
	// 	})
	// },
	DeleteSupervisor({ commit }, supervisor) {
		return new Promise((resolve, reject) => {
			api.post('/api/supervisors/' + supervisor, { _method: 'DELETE' })
				.then((res) => {
					commit('DELETE_SUPERVISOR', supervisor)
					resolve(res)
				})
				.catch((err) => reject(err))
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
