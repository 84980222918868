import Vue from 'vue'
import api from '../../api'

import { jsonParse } from 'vuelpers'
import { Channel, DOMAIN, ROLES, THEME_PATH } from '../../consts'
import { toVuetifyThemes } from '../../plugins/vuetify'
import { setAuthorizationToken } from '../../mixins/useAuthorization'
import get from 'lodash/get'
import isString from 'lodash/isString'
import initializePusherJS from '@/helpers/initializePusherJS'
import router from '@/router'

let state = {
	token: '',
	user: {
		company: {},
	},
}

let getters = {
	isLoggedIn: (state) => !!state.token,
	user(state) {
		const user = {
			// ...(state.user || {}),
			...(state.user || {}),
		}
		const roleSlugs = user?.user_roles?.map((userRole) => userRole.slug) || []

		const hasRole = (...roles) => {
			return roles.some((role) => {
				return roleSlugs.includes(role)
			})
		}

		const isAdmin = hasRole(ROLES.ADMIN)
		const isCompany = hasRole(ROLES.COMPANY)
		const isOperator = hasRole(ROLES.OPERATOR)
		const isSupervisor = hasRole(ROLES.SUPERVISOR)
		const isStore = hasRole(ROLES.STORE)
		const isDriver = hasRole(ROLES.DRIVER)
		const isLikeCompany = hasRole(
			ROLES.ADMIN,
			ROLES.COMPANY,
			ROLES.OPERATOR,
			ROLES.SUPERVISOR
		)

		let companyTheme = get(user, THEME_PATH[roleSlugs[0]])
		if (isString(companyTheme)) companyTheme = JSON.parse(companyTheme)
		companyTheme = toVuetifyThemes(companyTheme)

		const computedUser = {
			...user,

			// ROLES
			isAdmin,
			isCompany,
			isOperator,
			isSupervisor,
			isLikeCompany,
			isStore,
			isDriver,
			roleSlugs,
			role: roleSlugs[0],
			hasRole,

			companyTheme,
			token: state.token,
			isLoggedIn: !!(user.id && state.token),
			companyId:
				state.company_id ||
				user.company_id ||
				user.company?.id ||
				user.supervisor?.company_id ||
				user.driver?.company_id ||
				user.store?.company_id,
		}
		Vue.prototype.$user = computedUser
		return computedUser
	},
	authUser: (state) => JSON.parse(state.user),
	authStatus: (state) => state.status,
}
let mutations = {
	CREATE_USER(state, user) {
		state.user.unshift(user)
	},
	auth_request(state) {
		state.status = 'loading'
	},
	AUTH_SUCCESS(state, token) {
		state.token = token
	},
	USER_DATA(state, user = {}) {
		state.user = {
			...state.user,
			...user,
		}
	},
	USER_UPDATE(state) {
		state.status = 'success'
	},
	USER_DELETE(state, id) {
		state.status = 'success'
		state.user = state.user.filter((user) => user.id !== id)
	},
	logout(state) {
		state.token = ''
		state.roles = ''
		state.user = {}
	},
	SET_USER(state, payload) {
		state.user = payload
	},
}

let actions = {
	login({ commit, dispatch }, user) {
		return new Promise((resolve, reject) => {
			commit('auth_request')
			return api
				.post('/api/login', user)
				.then((res) => {
					const data = res.data.data
					const token = data.token
					const user = data.user

					setAuthorizationToken(token)
					initializePusherJS({
						token,
						user_id: user.id,
						company_id: data.company_id,
					})

					dispatch('userData')

					commit('AUTH_SUCCESS', token)
					commit('USER_DATA', user)

					resolve(res)
				})
				.catch((err) => {
					localStorage.removeItem('token')
					reject(err)
				})
		})
	},
	userData({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			api.get('/api/users/details')
				.then((res) => {
					// const user = res.data.data
					// let local = JSON.stringify(user)
					// localStorage.setItem('user', local)
					commit('USER_DATA', res.data.data)
					resolve(res)
				})
				.catch((err) => {
					dispatch('onLoggedOut')
					reject(err)
				})
		})
	},
	UpdateUserStatus({ commit }, user) {
		return new Promise((resolve, reject) => {
			api.post(`/api/users/update-status`, user)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	UserUpdate({ commit }, user) {
		return new Promise((resolve, reject) => {
			api.post(`/api/users/update`, user)
				.then((res) => {
					if (!res.data.error) {
						commit('USER_DATA', res.data.user)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// Userdelete({ commit }, id) {
	// 	return new Promise((resolve, reject) => {
	// 		api.delete(`/api/user/delete/${id}`)
	// 			.then((res) => {
	// 				commit('USER_DELETE', id), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	Userdelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.post(`/api/user/delete/${id}`, { _method: 'DELETE' })
				.then((res) => {
					commit('USER_DELETE', id), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	Reset({ commit }, email) {
		return new Promise((resolve, reject) => {
			api.post('/api/password/forgot', email)
				.then((res) => {
					commit('USER_UPDATE')

					resolve(res)
				})
				.catch((err) => {
					commit('loginFailure')

					reject(err)
				})
		})
	},
	ChangePassword({ commit }, data) {
		return new Promise((resolve, reject) => {
			api.post('/api/users/change-password', data)
				.then((res) => {
					commit('USER_UPDATE')

					resolve(res)
				})
				.catch((err) => {
					commit('loginFailure')

					reject(err)
				})
		})
	},
	ResetChange({ commit }, data) {
		return new Promise((resolve, reject) => {
			api.post('/api/password/reset', data)
				.then((res) => {
					commit('USER_UPDATE')

					resolve(res)
				})
				.catch((err) => {
					commit('loginFailure')

					reject(err)
				})
		})
	},
	TokenCheck({ commit }, token) {
		return new Promise((resolve, reject) => {
			api.get(`/api/password/check?token=${token}`)
				.then((res) => {
					commit('USER_UPDATE')

					resolve(res)
				})
				.catch((err) => {
					commit('loginFailure')

					reject(err)
				})
		})
	},
	onLoggedOut({ commit, dispatch }) {
		delete api.defaults.headers.common['Authorization']
		commit('logout')
		dispatch('pusher/resetPusherState', null, { root: true })
		;['roles', 'user', 'token', DOMAIN].forEach((key) => {
			localStorage.removeItem(key)
		})
		router.push('/login').catch(() => {})
	},
	logout({ dispatch, getters: { user } }) {
		return new Promise((resolve) => {
			return api
				.get('/api/logout')
				.then((res) => {
					window.Echo.leave(Channel.ActiveUser(user.companyId))
					window.Echo.leave(Channel.Notifications(user.id))

					dispatch('onLoggedOut')
					return resolve([null, res])
				})
				.catch((err) => {
					dispatch('onLoggedOut')
					resolve([err])
				})
		})
	},
	oAuthCallback({ commit }, code) {
		return new Promise((resolve, reject) => {
			api.get('/api/oauth/callback?code=' + code)
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
