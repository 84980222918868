import api from '../../api'
import {
	convertKeysToCamelCase,
	createGetters,
	createPaginaion,
	toFormData,
} from 'vuelpers'

const state = () => ({
	webPages: createPaginaion(),
})

const getters = {
	...createGetters({
		webPages: (state, _getter, _rootState, _rootGetters) => ({
			...state.webPages,
			data: state.webPages.data.map((webPage) => ({
				...webPage,
			})),
		}),
	}),
}
const mutations = {
	FETCH_WEB_PAGES(state, webPages) {
		state.webPages = webPages
	},
}
const actions = {
	fetchWebPages({ commit }, params = {}) {
		return new Promise((resolve) => {
			api.get(`/api/web-page`, { params })
				.then((res) => {
					commit('FETCH_WEB_PAGES', res.data.webPages)
					resolve([false, res])
				})
				.catch((err) => resolve([true, err.response || err]))
		})
	},
	createWebPage(_, payload) {
		return new Promise((resolve, reject) => {
			api.post('/api/web-page', toFormData(payload))
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	updateWebPage(_, payload) {
		return new Promise((resolve, reject) => {
			api.post(`/api/web-page/update/${payload.id}`, toFormData(payload))
				.then((res) => {
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},

	// deleteWebPage(_, id) {
	// 	return new Promise((resolve, reject) => {
	// 		api.delete(`/api/web-page/${id}`)
	// 			.then((res) => {
	// 				resolve([false, res])
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	deleteWebPage(_, id) {
		return new Promise((resolve, reject) => {
			api.post(`/api/web-page/${id}`, { _method: 'DELETE' })
				.then((res) => {
					resolve([false, res])
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
