import api from '../../api'
import { createGetters, createPaginaion } from 'vuelpers'

const state = () => ({
	operators: createPaginaion(),
})

const getters = createGetters('operators')

const mutations = {
	CREATE_OPERATORS(state, operator) {
		state.operators.total += 1
		state.operators.data.unshift(operator)
	},

	OPERATORS_DATA(state, operators) {
		state.operators = operators
	},
	OPERATORS_UPDATE(state, operatorsData) {
		state.status = 'success'

		const index = state.operators.data.findIndex(
			(operators) => operators.id === operatorsData.id
		)
		if (index !== -1) {
			state.operators.data.splice(index, 1, operatorsData)
		}
	},
	OPERATORS_DELETE(state, id) {
		state.status = 'success'
		state.operators = state.operators.data.filter(
			(operators) => operators.id !== id
		)
	},
}

const actions = {
	CreateOperator({ commit }, operator) {
		return new Promise((resolve, reject) => {
			api.post('/api/operators/create', operator)
				.then((res) => {
					if (!res.data.error) {
						const operator = res.data
						commit('CREATE_OPERATORS', operator)
					}
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	OperatorsData({ commit }, params = {}) {
		return new Promise((resolve, reject) => {
			api.get('/api/operators/index', { params })
				.then((res) => {
					commit('OPERATORS_DATA', res.data.operators)
					resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	OperatorsUpdate({ commit }, operators) {
		return new Promise((resolve, reject) => {
			api.post('/api/operators/update', operators)
				.then((res) => {
					const operatorsData = res.data.operator
					commit('OPERATORS_UPDATE', operatorsData), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
	// OperatorDelete({ commit }, id) {
	// 	return new Promise((resolve, reject) => {
	// 		api.delete(`/api/operators/delete/${id}`)
	// 			.then((res) => {
	// 				commit('OPERATORS_DELETE', id), resolve(res)
	// 			})
	// 			.catch((err) => {
	// 				reject(err)
	// 			})
	// 	})
	// },
	OperatorDelete({ commit }, id) {
		return new Promise((resolve, reject) => {
			api.post(`/api/operators/delete/${id}`, { _method: 'DELETE' })
				.then((res) => {
					commit('OPERATORS_DELETE', id), resolve(res)
				})
				.catch((err) => {
					reject(err)
				})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
